import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'waterQuantity'
})
export class WaterQuantityPipe implements PipeTransform {

  metrics: string;
  constructor(
    private sharedDataService: SharedDataService
  ) {
    this.metrics = this.sharedDataService.getAttribute('userDetails').user_details.metrics;
  }

  transform(waterInMl: number, ...args: unknown[]): any {
    if (isNaN(waterInMl)) {
      return '0';
    }
    const value = this.metrics == 'liters' ? waterInMl / 1000 : waterInMl * 0.000264172;


    const roundedValue = value.toFixed(1);


    return roundedValue.toString();
  }
}
@Pipe({
  name: 'waterQuantity2'
})
export class WaterQuantity2Pipe implements PipeTransform {

  metrics: string;
  constructor(
    private sharedDataService: SharedDataService
  ) {
    this.metrics = this.sharedDataService.getAttribute('userDetails').user_details.metrics;
  }

  transform(waterInMl: number, ...args: unknown[]): any {
    if (isNaN(waterInMl)) {
      return '0';
    }
    const value = this.metrics == 'liters' ? waterInMl / 1000 : waterInMl * 0.000264172;


    const roundedValue = value.toFixed(1);


    return roundedValue.toString();
  }
}
@Pipe({
  name: 'waterQuantityLabel'
})
export class WaterQuantityLabelPipe implements PipeTransform {

  constructor(
  ) { }

  transform(metrics: string, ...args: unknown[]): string {
    return {
      liters: 'Liters',
      gallon: 'Gallons'
    }[metrics] || '';
  }
}
