import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from './services/dialog.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../modules/material.module';
import { NavigationService } from './services/navigation.service';
import { ErrorComponent } from './components/error/error.component';
import { LoadingService } from './services/loader.service';
import { NotificationService } from './services/notification.service';
import { SharedDataService } from './services/shared-data.service';
import { AppLoadService } from './services/app-load.service';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DataService } from './services/data.service';
import { FullNamePipe } from './pipes/full-name.pipe';
import { CanLoadModuleGuard } from './guard/can-load-module.guard';
import { AuthGuard } from './guard/auth.guard';
import { ListService } from './services/list.service';
import { DateAgePipe } from './pipes/date-age.pipe';
import { WaterQuantityPipe, WaterQuantityLabelPipe, WaterQuantity2Pipe } from './pipes/water-quantity.pipe';
import { TrackByPropertyPipe } from './pipes/track-by-property.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
// import { ConfirmComponent } from './components/confirm/confirm.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { AlarmTypePipe } from './pipes/alarm-type.pipe';
import { EmptyScreenComponent } from './components/empty-screen/empty-screen.component';
import { ModuleAccessGuard } from './guard/module-access.guard';
// import { ActivityDetailComponent } from './sidebar/activity-detail/activity-detail.component';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClearingStatusPipe } from './pipes/clearing-status.pipe';
//import { GlobalSidenavComponent } from './components/global-sidenav/global-sidenav.component';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';
import { DialogServiceNew } from './services/new-dilog.service';
import { LikeAnimationComponent } from './components/like-animation/like-animation.component';
// import { SidenavService } from './services/sidenav.service';

// Restore shared data from localstorage before app loads
export function restoreSharedData(appLoadService: AppLoadService) {
  return () => appLoadService.restoreSharedData();
}
@NgModule({
  declarations: [
    HeaderComponent,
    ErrorComponent,
    FullNamePipe,
    ShortNamePipe,
    ClearingStatusPipe,
    DateAgePipe,
    WaterQuantityPipe,
    WaterQuantityLabelPipe,
    TrackByPropertyPipe, WaterQuantity2Pipe, UtcToLocalPipe,
    // ConfirmComponent,
    ImagePreviewComponent,
    AlarmTypePipe,
    EmptyScreenComponent,
    //GlobalSidenavComponent,
    // ActivityDetailComponent
  ],
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule, LikeAnimationComponent,
    RouterModule,
    NgxUiLoaderModule,
    InfiniteScrollModule
  ],
  exports: [
    HeaderComponent, LikeAnimationComponent,
    FlexLayoutModule,
    MaterialModule,
    ClearingStatusPipe,
    FullNamePipe,
    ShortNamePipe,
    DateAgePipe,
    WaterQuantityPipe,
    WaterQuantityLabelPipe,
    TrackByPropertyPipe, UtcToLocalPipe,
    // ConfirmComponent,
    ImagePreviewComponent, WaterQuantity2Pipe,
    AlarmTypePipe,
    EmptyScreenComponent,
    // ActivityDetailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DialogService, DialogServiceNew,
    NavigationService,
    LoadingService, WaterQuantityLabelPipe,
    WaterQuantityPipe, WaterQuantity2Pipe, UtcToLocalPipe, LikeAnimationComponent,
    NotificationService,
    SharedDataService,
    AppLoadService,
    AuthService,
    DataService,
    ListService,
    // SidenavService,
    CanLoadModuleGuard,
    ModuleAccessGuard,
    AuthGuard,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: APP_INITIALIZER, useFactory: restoreSharedData, deps: [AppLoadService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule { }
