import { Injectable } from '@angular/core';
import { DataService } from './data.service';


export interface Config {
  self: any; // Reference to the component or service using this config
  listName: string; // Name of the list to manage
  url: string; // API URL to fetch data
  callback?: any; // Optional callback function after data is fetched
  skip: number; // Number of records to skip for pagination
  limit: number; // Number of records to fetch per request
  scrollContainer?: any; // Optional selector for scroll container
  fields?: string[]; // Optional fields for search
  check?: any[]; // Optional filter conditions for search
}

@Injectable({
  providedIn: 'root'
})
export class ListService {

  config: Config; // Configuration for the main list
  searchConfig: Config; // Configuration for search functionality

  public isLoading = false; // Flag to indicate loading state
  public fromRoot = true; // Indicates whether to use the root configuration
  public scrollDistance = 1; // Distance before triggering infinite scroll
  public scrollThrottle = 150; // Throttle time for scroll events
  public scrollDisabled = true; // Flag to disable/enable scrolling
  public scrollContainer = '.mat-sidenav-content'; // Default scroll container selector


  constructor(
    private dataService: DataService // Injected service for making HTTP requests
  ) {
     // Initialize default configurations
    this.config = {
      self: '',
      listName: '',
      url: '',
      skip: 0,
      limit: 15
    };

    this.searchConfig = {
      self: '',
      listName: '',
      url: '',
      skip: 0,
      limit: 15
    };
  }
 /**
   * Initializes the configuration for lists and optionally sets data and search config.
   * @param config Configuration object for the list.
   * @param data Optional initial data for the list.
   * @param searchConfig Optional search configuration.
   * @param skip Optional flag to reset pagination skip.
   */
  init(config: Config, data?: any[], searchConfig?: any, skip?: any) {
    this.config = config;
    this.config.skip = this.config?.skip || 0;
    this.config.limit = this.config?.limit || 15;
    this.config.self[this.config?.listName] = [];
    if (skip == true) {
      this.config.skip = 0;
    }
    console.log(this.config);

    this.scrollContainer = this.config.scrollContainer || '.mat-sidenav-content';
    this.appendResult(data);
    console.log(this.config.self[this.config.listName]);

    this.searchConfig = searchConfig || this.searchConfig;
    this.searchConfig.skip = this.searchConfig?.skip || 0;
    this.searchConfig.limit = this.searchConfig?.limit || 15;
  }
 /**
   * Appends new results to the existing list and handles pagination.
   * @param list List of new records to be added.
   * @param retainLimit Flag to retain the limit for pagination.
   */
  appendResult(list?: any[], retainLimit?: boolean) {
    if (Array.isArray(list)) {
      this.config.self[this.config.listName] = this.config.skip == 0 ? list : this.config.self[this.config.listName].concat(list);

      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      this.config.skip += list.length;
      this.scrollDisabled = list.length < this.config.limit;

      if (typeof this.config.callback === "function") {
        this.config.callback();
      }

    } else {
      this.scrollDisabled = true;
    }
  }
  /**
   * Fetches the list of records from the server.
   * @param config Optional configuration for this request.
   * @param retainLimit Flag to retain the limit for pagination.
   */

  getList(config: any = {}, retainLimit?: boolean) {
    if (this.config.self.isSearch) {
      this.getSearchList();
      return;
    }

    let limit = this.config.limit;
    if (retainLimit) {
      this.config.skip = 0;
      const len = this.config.self[this.config.listName]?.length;
      limit = len < this.config.limit ? this.config.limit : len;
    }

    this.isLoading = true;
    this.dataService.get({
      url: this.config.url + "skip=" + this.config.skip + "&limit=" + limit,
      isLoader: false
    }).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.result) {
        if (typeof config.callback === "function") {
          config.callback(response.result);
        }
        this.appendResult(response.result.records, retainLimit);
      }
    }, () => {
      this.isLoading = false;
    });
  }
 /**
   * Sets the API URL with filters and fetches the list.
   * @param baseURL Base URL for the API.
   * @param filterString Filter parameters to append to the URL.
   */
  setFilterApiUrl(baseURL: string, filterString: string) {
    this.config.url = baseURL + filterString;
    this.getList(true);
  }
/**
   * Appends new results to the search list and handles pagination.
   * @param list List of new records to be added.
   */
  appendSearchResult(list: any[]) {
    if (Array.isArray(list)) {
      this.searchConfig.self[this.searchConfig.listName] = this.searchConfig.skip == 0 ? list : this.searchConfig.self[this.searchConfig.listName].concat(list);

      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      this.searchConfig.skip += list.length;

      this.scrollDisabled = list.length < this.searchConfig.limit;

      if (typeof this.searchConfig.callback === "function") {
        this.searchConfig.callback();
      }
    } else {
      this.scrollDisabled = true;
    }
  }
/**
   * Fetches the list of search results from the server.
   */
  getSearchList() {
    if (!this.config.self.isSearch) {
      this.getList();
      return;
    }

    const data = {
      query: {
        keyword: this.searchConfig.self.searchText.replace(/[`~!#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
        fields: this.searchConfig.fields,
        offset: this.searchConfig.skip,
        limit: this.searchConfig.limit,
        check: this.searchConfig.check || []
      }
    };

    this.isLoading = true;
    this.dataService.post({
      url: this.searchConfig.url,
      isLoader: false,
      data
    }).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.result) {
        this.appendSearchResult(response.result);
      }
    }, () => {
      this.isLoading = false;
    });
  }
/**
   * Initiates a search based on the search text in the configuration.
   */
  search() {
    if (this.searchConfig.self.searchText) {
      this.searchConfig.self.isSearch = true;
      this.getSearchList();
    } else if (this.searchConfig.self.searchText === '') {
      this.clearSearch();
    }
  }
/**
   * Clears the search text and resets pagination and limits.
   */
  clearSearch() {
    this.searchConfig.self.searchText = '';
    this.searchConfig.self.isSearch = false;
    this.searchConfig.skip = this.config.skip = 0;
    this.searchConfig.limit = this.config.limit = 15;
    this.getList();
  }
}
