import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(data: any, ...args: unknown[]): unknown {

    if (!data?.first_name) {
      return '-';
    }

    // Split the first and last name
    const firstName = data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1).toLowerCase();
    const lastName = data.last_name ? data.last_name.charAt(0).toUpperCase() + data.last_name.slice(1).toLowerCase() : '';

    // Concatenate and return the full name
    return `${firstName} ${lastName}`;
  }

}
