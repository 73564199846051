<div class="fh-container">
    <div class="fh-content fh">
        <div fxLayout="column" fxFlex="100"
            class="system-connectivity my-profile my-profile-detail home-container report-container set-up-section">

            <div fxLayout="column" fxFlex="100" class="system-connectivity settings support-landing">

                <div flex fxLayout="row" fxLayoutAlign="space-between center" class="water-saved-report-section">
                    <img src="../../../assets/images/Star-icon.svg" class="header-icon invite-header-icon"
                        style="height: 28px;width: 28px;">
                    <div flex fxLayout="column" class="water-saved-report-div" fxLayoutAlign="space-between start">
                        <div class="heading saved-report bg-white prl mb-0" flex fxLayout="row"
                            fxLayoutAlign="space-between center">
                            <div flex fxLayout="row" class="heading-badge">
                                <h2 class="font-bold">Pick An Area</h2>
                            </div>
                            <div class="border-section"></div>
                            <div flex fxLayout="row" fxLayoutAlign="space-around end" class="status-section">

                                <div fxLayout="column" class="invite right-arrow" fxLayoutAlign="center">
                                    <mat-icon class="header-icon invite-header-icon"
                                        svgIcon="icon-ico-profile"></mat-icon>
                                    <p class="text-center">Invite</p>
                                </div>
                                <div fxLayout="column" class="settings right-arrow">
                                    <mat-icon class="header-icon settings-header-icon"
                                        svgIcon="icon-ico-profile"></mat-icon>
                                    <p class="text-center">Settings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div flex fxLayout="row" fxLayoutAlign="start" class="profile-img-section">
                    <div fxLayout="column" class="profile-img">
                        <input class="file-upload" type="file" accept="image/*" style="display: none;" #file
                            (change)="readUrl($event)" />

                        <img *ngIf="!(profileImgUrl==''||profileImgUrl==null)" class="user-img-holder"
                            style="height: 60px;width: 60px;" (click)="profileImgUrl && imagePreview(profileImgUrl)"
                            [src]="
                                    profileImgUrl || './../../../../assets/images/dummy-profile.jpeg'
                                  " />
                        <div class="user-img-holder" style="height: 60px;width: 60px;"
                            *ngIf="profileImgUrl==''||profileImgUrl==null" (click)="file.click()">


                            <span class="img-alt">{{ data | shortName }}</span>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start" class="system-connectivity circle-header">
                        <h2>Hi, Vimal</h2>
                        <p class="text-left">Manage your profile and settings.
                            Invite a member to share system details.
                        </p>
                    </div>
                </div>
                <form fxLayout="column" [formGroup]="form" (ngSubmit)="form.valid && updateProfile(form.value)"
                    class="form-section">
                    <mat-form-field class="full-width display-block">
                        <mat-label>First Name*</mat-label>
                        <input matInput placeholder="First Name" formControlName="first_name" />
                        <mat-error *ngIf="form.controls['first_name'].hasError('required')">
                            Field is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="100" color="accent">
                        <mat-label>Middle Name</mat-label>
                        <input matInput placeholder="Middle Name" formControlName="middle_name" />
                    </mat-form-field>

                    <mat-form-field fxFlex="100" color="accent">
                        <mat-label>Last Name*</mat-label>
                        <input matInput placeholder="Last Name" formControlName="last_name" />
                        <mat-error *ngIf="form.controls['last_name'].hasError('required')">
                            Field is <strong>required*</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" color="accent">
                        <mat-label>User Email*</mat-label>
                        <input matInput placeholder="User Email" formControlName="email" />
                        <mat-error *ngIf="form.controls['email'].hasError('pattern')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="form.controls['email'].hasError('required')">
                            Email is <strong>required*</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" color="accent">
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="User phone" formControlName="phone" />
                        <mat-error *ngIf="form.controls['phone'].hasError('pattern')">
                            Please enter a valid phone number
                        </mat-error>
                        <mat-error *ngIf="form.controls['phone'].hasError('required')">
                            Phone is <strong>required*</strong>
                        </mat-error>
                    </mat-form-field>


                </form>
                <div class="form-section">
                    <div class="text-center bottom-button" fxLayout="row" fxLayoutAlign="center center"
                        fxLayoutGap="30px">
                        <button mat-stroked-button color="warn" type="button" class="secondary-btn">
                            Cancel
                        </button>
                        <button class="btn-large primary" mat-raised-button color="warn"
                            (click)="updateProfile(form.value)">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>