// import { Component, Inject, OnInit } from '@angular/core';
// import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
// import { MatIconRegistry } from '@angular/material/icon';
// import { DomSanitizer } from '@angular/platform-browser';

// @Component({
//   selector: 'app-image-preview',
//   templateUrl: './image-preview.component.html',
//   styleUrls: ['./image-preview.component.scss']
// })
// export class ImagePreviewComponent implements OnInit {

//   constructor(
//     public dialogRef: MatDialogRef<ImagePreviewComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any,
//     iconRegistry: MatIconRegistry,
//     sanitizer: DomSanitizer
//   ) {
//     iconRegistry.addSvgIconSet(
//       sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/sprite.svg'));

//     console.log(data)
//   }

//   ngOnInit(): void {
//   }

//   close(): void {
//     this.dialogRef.close();
//   }
// }

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconSet(
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/sprite.svg'));

    console.log(data)
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
}
