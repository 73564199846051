import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/constants/apiUrl.constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataService } from 'src/app/core/services/data.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';


@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
  form: FormGroup;
  hide = true;
  imageUrl: string | ArrayBuffer = '';
  WIDTH = 300;
  HEIGHT = 200;


  url: any;
  uploadProgress = -1;
  fileType: any;
  fileName: any;
  validFile = false;
  fileSize: any;
  private subscription: Subscription | undefined;
  profileImgUrl = ''
  @Input('id') id: string | undefined | null;
  @Input('formData') formData: any | undefined;

  @ViewChild("video")
  public video!: ElementRef;

  @ViewChild("canvas")
  public canvas!: ElementRef;

  captures: string = '';
  error: any;
  isCaptured!: boolean;
  data = {
    first_name: 'Dev',
    last_name: "Cham"
  }


  shortName = {
    first_name: '',
    last_name: ""
  }


  constructor(
    public nav: NavigationService,
    private sharedData: SharedDataService,
    private dailogService: DialogService,
    private fb: FormBuilder,
    private dataService: DataService,
    public authService: AuthService,
    private notification: NotificationService,
  ) {
    this.formData = this.authService.getUserDetail();
    this.id = this.formData.id;

    this.form = this.fb.group({

      first_name: new FormControl('', [
        RxwebValidators.required()
      ]),
      middle_name: new FormControl('', [
        // RxwebValidators.required()
      ]),
      last_name: new FormControl('', [
        RxwebValidators.required()
      ]),
      email: new FormControl('', [
        RxwebValidators.required(),
        RxwebValidators.email(),
      ]),
      phone: new FormControl('', [
        RxwebValidators.required()
      ]),
      is_admin: new FormControl(false)
    });

  }
  ngOnInit() {
    console.log(this.id)
    if (this.id) {

      // this.formData = this.authService.getUserDetail();
      // this.formData.profile_image = this.sharedData.getAttribute('profile_image')
      // this.form.patchValue(this.formData);
      // this.form.controls['email'].disable();
      // console.log(this.formData)
      // console.log(this.form)

      // this.profileImgUrl = this.formData.profile_image;
      // this.shortName.first_name = this.formData.first_name;
      // this.shortName.last_name = this.formData.last_name;
    }

  }

  // async ngAfterViewInit() {
  //   await this.setupDevices();
  // }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = "You have no output video device";
        }
      } catch (e) {
        this.error = e;
      }
    }
  }


  capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures = this.canvas.nativeElement.toDataURL("image/png");
    this.imageUrl = this.captures
    this.isCaptured = true;
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  setPhoto(idx: number) {
    this.isCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0]; // Get the selected file

    if (file) {
      // Read the file as a data URL
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result; // Set the image URL
      };
      reader.readAsDataURL(file);
    }
  }
  back() {
    this.nav.back();
  }

  updateProfile(formData: any) {
    console.log(formData);
    console.log(this.form.valid)


    if (this.form.valid) {

      // this.authService.updateLoginUser(formData).subscribe((response: any) => {
      //   if (response && response.result) {
      //     let userDetails = this.sharedData.getAttribute('userDetails');
      //     userDetails.user_details = { ...userDetails?.user_details, ...formData };
      //     this.sharedData.setAttribute('userDetails', userDetails);
      //     this.notification.toast('Profile updated successfully!');

      //   }
      // });
    }
  }



  imagePreview(imgUrl: string) {
    this.dailogService.openImagePreview({ imgUrl: imgUrl, width: '500px' });
  }

  uploadFile(file: any, type: any, selfEvent = this) {
    const data = {
      file: file,
      extension: type
    };
    let result: any;
    selfEvent.subscription = selfEvent.dataService.postProgress({ url: ApiUrls.Base64_Upload, data, isLoader: false }).subscribe((resp: any) => {
      console.log(resp);

      if (resp.type === HttpEventType.Response) {
        console.log('Upload complete', resp.body.valueOf());
        this.profileImgUrl = resp.body.valueOf().result.url;
      }
      if (resp.type === HttpEventType.UploadProgress) {
        selfEvent.uploadProgress = Math.round(100 * resp.loaded / resp.total);
        console.log('Progress ' + selfEvent.uploadProgress + '%');
      }
    });
  }

  readUrl(event: any) {
    console.log(event);

    if (event.target.files && event.target.files[0]) {
      this.fileType = event.target.files[0].type.replace('image/', '');
      this.fileName = event.target.files[0].name;
      this.fileSize = event.target.files[0].size;

      // tslint:disable-next-line:max-line-length
      if (['png', 'jpg', 'jpeg'].indexOf(this.fileType) !== -1 && ((this.fileSize / 1048576) <= 2 || ((this.fileSize / 1048576) === 2 && (this.fileSize % 1048576) === 0))) {
        this.validFile = true;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.url = e.target.result;
          this.uploadFile(this.url, this.fileType, this);
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        console.log(this.fileType);
        if (['png', 'jpg', 'jpeg'].indexOf(this.fileType) === -1) {
          this.notification.toast('Supported file types are jpg, png, jpeg');
        } else {
          // if ((this.fileSize / 1024) < 2) {
          //   this.notification.toast('File size is less than 2 kb.');
          // } if ((this.fileSize / 1024 / 1024) > 2) {
          this.notification.toast('File size greater than 2 MB.');
          // } else {

          // }
        }
      }
    }
  }
}
