<div class="center" style="z-index: 1000000;">
    <img src="../../../assets/images/like_1.png" class="thumb" alt="Thumbs Up" />

    <div class="circle-wrap">
        <div class="circle-lg"></div>
    </div>
    <div class="dots-wrap">
        <div class="dot dot--t"></div>
        <div class="dot dot--tr"></div>
        <div class="dot dot--br"></div>
        <div class="dot dot--b"></div>
        <div class="dot dot--bl"></div>
        <div class="dot dot--tl"></div>
    </div>
</div>