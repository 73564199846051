// import { Component, Inject, OnInit } from '@angular/core';
// import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// @Component({
//   selector: 'app-error',
//   templateUrl: './error.component.html',
//   styleUrls: ['./error.component.scss']
// })
// export class ErrorComponent {


//   constructor(
//     public dialogRef: MatDialogRef<ErrorComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any
//   ) {

//   }

//   close(): void {
//     this.dialogRef.close();
//   }
// }
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close(): void {
    this.dialogRef.close();
  }
}
