import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-like-animation',
  standalone: true,
  templateUrl: './like-animation.component.html',
  styleUrls: ['./like-animation.component.scss']
})
export class LikeAnimationComponent {
  @Input() show: boolean = false;
}
