import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { GlobalFilterComponent } from '../dashboard/global-filter/global-filter.component';
import { DataService } from 'src/app/core/services/data.service';
import { ApiUrls } from 'src/app/core/constants/apiUrl.constants';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {
  userDetail: any; // Stores the details of the current user
  isGallonView: boolean = false; // Flag to toggle between gallon and liters view
  menus: any[]; // Array of menu items to be displayed
  constructor(
    private dialogService: DialogService,
    private auth: AuthService,
    private dataService: DataService,
    private sharedData: SharedDataService
  ) {
    this.userDetail = this.auth.getUserDetail();
// Define the menu items with visibility based on user permissions or other criteria
    // isShow: this.userDetail.is_admin
    this.menus = [
      { name: 'Dashboard', iconName: 'icon-Dashboard_1', link: '/admin/dashboard', isShow: true },
      { name: 'Reports', iconName: 'icon-Reports', link: '/admin/trends', isShow: true },
      { name: 'Active Alerts', iconName: 'icon-Alerts', link: '/admin/activity-alerts', isShow: false },
      { name: 'Logs', iconName: 'icon-Systems', link: '/admin/logs', isShow: false },
      { name: 'Systems', iconName: 'icon-Systems', link: '/admin/systems', isShow: false },
      { name: 'Alerts', iconName: 'icon-Alerts', link: '/admin/alerts', isShow: true },
      { name: 'Connectivity', iconName: 'icon-Networking', link: '/admin/connectivity', isShow: true },
      { name: 'Customers', iconName: 'icon-ico-customers', link: '/admin/customers', isShow: false },
      { name: 'Users', iconName: 'icon-ico-users', link: '/admin/users', isShow: false },
      { name: 'Setup', iconName: 'icon-Setup', link: '/admin/users-profile', isShow: true },
      // { name: 'Setup', iconName: 'icon-ico-users', link: '/admin/invite-members', isShow: true },
      { name: 'Settings', iconName: 'icon-ico-settings', link: '/admin/settings', isShow: false },
    ];
 // Subscribe to changes in shared data, specifically listening for profile updates
    this.sharedData.changeEmitted$.subscribe(changeText => {
      if (changeText === 'profile_updated') {
        this.userDetail = this.auth.getUserDetail();
      }
    });
  }
 /**
   * Opens a dialog for global filter component.
   */
  openFilter() {
    this.dialogService.openDialogComponent(GlobalFilterComponent, { class: ['custom-dialog'] }, '880px');
  }
 /**
   * Logs out the user by calling the AuthService's logout method.
   */
  logout() {
    this.auth.logout();
  }
  /**
   * Toggles the view between gallon and liters and updates the user metrics preference.
   * @param checked Boolean indicating whether the gallon view is selected.
   */
  toggleChanged(checked: boolean) {
    this.isGallonView = checked;
    this.updateMetricsView(checked)
  }

  /**
   * Updates the user's metrics view preference in the backend.
   * @param checked Boolean indicating whether the gallon view is selected.
   */
  updateMetricsView(checked: boolean) {
    const data = {
      'metrics': checked == true ? 'gallon' : "liters"
    };
    this.dataService.put({
      url: `${ApiUrls.Global_settings}`,
      data: data,
      isLoader: true
    }).subscribe((response: any) => {
      console.log(response)
      if (response && response.result) {
        console.log(response.result)
        console.log(this.userDetail)
        this.userDetail.metrics = data['metrics']
        let userDetails = this.sharedData.getAttribute('userDetails');
        userDetails.user_details = { ...userDetails?.user_details, ...this.userDetail };
        this.sharedData.setAttribute('userDetails', userDetails);
        window.location.reload();
      }
    });
  }
}
