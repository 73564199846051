import { Injectable } from '@angular/core';
import { forEach } from 'lodash';

import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';

// Declared as const as this should not be reassigned from anywhere in app
const sharedData: any = {};

@Injectable()
export class SharedDataService {

  constructor(private storage: LocalStorageService) { }

  // Observable string sources
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  get() {
    return sharedData;
  }

  clear() {
    // Clearing sharedData one by one as a constant cannot be reassigned
    forEach(sharedData, (val, key) => {
      delete sharedData[key];
    });
    // Clear localStorage
    this.storage.clear();
  }

  getAttribute(key: string) {
    return sharedData[key];
  }

  setAttribute(key: string, value: any) {
    sharedData[key] = value;
    this.storage.store(key, value);
  }

  deleteAttribute(key: string) {
    delete sharedData[key];
    this.storage.clear(key);
  }

  restoreSharedData() {
    const len = localStorage.length;
    for (let i = 0; i < len; i++) {
      // Get plain key removing prefix and seperator
      const key: any = localStorage?.key(i)?.replace('greyter-consumer.', '');
      sharedData[key as keyof any] = this.storage.retrieve(key);
    }
  }

}
