import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanLoadModuleGuard } from '../core/guard/can-load-module.guard';
import { AdminLayoutComponent } from '../pages/admin-layout/admin-layout.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { ProfileDetailsComponent } from '../pages/profile-details/profile-details.component';
//import { InviteMembersComponent } from '../pages/invite-members/invite-members.component';
import { ModuleAccessGuard } from '../core/guard/module-access.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./../pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad: [ModuleAccessGuard]
      }, {
        path: 'systems',
        loadChildren: () => import('./../pages/systems/systems.module').then(m => m.SystemsModule),
        canLoad: [ModuleAccessGuard]
      }, {
        path: 'trends',
        loadChildren: () => import('./../pages/trends/trends.module').then(m => m.TrendsModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./../pages/users/users.module').then(m => m.UsersModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile',
        loadChildren: () => import('./../pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
          canLoad: [ModuleAccessGuard]
      },
      {
        path: 'change-profile',
        loadChildren: () => import('./../pages/change-profile/change-profile.module').then(m => m.ChangeProfileModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile/invite-members',
        loadChildren: () => import('./../pages/invite-members/invite-members.module').then(m => m.InviteMembersModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile/system-settings',
        loadChildren: () => import('./../pages/system-info/system-info.module').then(m => m.SystemInfoModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile/account-deletion',
        loadChildren: () => import('./../pages/delete-account/delete-account.module').then(m => m.DeleteAccountModule),
        canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile/schedule-call',
        loadChildren: () => import('./../pages/support-request/support-request.module').then(m => m.SupportRequestModule),
      //  canLoad: [ModuleAccessGuard]
      },
      {
        path: 'users-profile/profile-details',
        component: ProfileDetailsComponent
      },
      {
        path: 'users-profile/profile-info',
        loadChildren: () => import('./../pages/profile-info/profile-info.module').then(m => m.ProfileInfoModule),
          canLoad: [ModuleAccessGuard]

      },

      {
        path: 'connectivity',
        loadChildren: () => import('./../pages/connectivity/connectivity.module').then(m => m.ConnectivityModule),
          canLoad: [ModuleAccessGuard]
      },
      {
        path: 'alerts',
        loadChildren: () => import('./../pages/alerts/alerts.module').then(m => m.AlertModule),
        canLoad: [ModuleAccessGuard]
      },
    ]
  },{
    path: 'get-started',
    loadChildren: () => import('./../pages/get-started/get-started.module').then(m => m.GetStartedComponentModule),
    //canLoad: [ModuleAccessGuard]
  
  },
  {
    path: 'auth',
    loadChildren: () => import('./../pages/auth/auth.module').then(m => m.AuthModule)
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
