import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'clearingStatus'
})
export class ClearingStatusPipe implements PipeTransform {

    constructor(
    ) {

    }

    transform(status: string): unknown {
        return {
            forced_cleared: 'Forced Cleared',
            cleared: 'Cleared',
            unclear: 'Unclear'
        }[status];
    }
}
