<div
  class="section-header posR {{ class[0] }}"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="5px"
>
  <div class="header-right-section {{ class[1] }}">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="space-right">
        <mat-icon svgIcon="icon-ico-star"></mat-icon>
      </div>
      <h3>
        <ng-content select="#headerLabel"></ng-content>
      </h3>
    </div>
  </div>
  <div class="border-section"></div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon class="header-icon">sentiment_satisfied_alt</mat-icon>
        </button>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
            <mat-icon class="header-icon material-icons-outlined">filter_alt</mat-icon>
        </button> -->
    <div class="button-section" *ngIf="isButtonShow">
      <button
        class="heading-button"
        mat-raised-button
        color="warn"
        (click)="goTo()"
      >
        <ng-content select="#buttonLabel"></ng-content>
      </button>
    </div>
  </div>
</div>
