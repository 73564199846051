import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogServiceNew {

    isAccessDeniedOpen = false;
    constructor(
        private dialog: MatDialog,
    ) { }

    openDialogComponent(component: ComponentType<unknown>, data: { errors?: any[], data?: any, class?: string[] }, width?: any): Observable<any> {

        const dialogRef = this.dialog.open(component, {
            width: width || '400px',
            data: data || {},
            panelClass: data?.class
        });
        return dialogRef.afterClosed();
    }

    closeAllDailog(): void {
        this.dialog.closeAll();
    }
}
