

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

    transform(value: number, format: string = 'mediumDate'): string {
        if (value === null || value === undefined) return '';

        // Convert UTC timestamp (in seconds) to a Date object
        const utcDate = new Date(value * 1000);

        // Define available formats
        const formats: { [key: string]: Intl.DateTimeFormatOptions } = {
            'mediumDate': { dateStyle: 'medium', timeZone: 'UTC' },
            'shortDate': { dateStyle: 'short', timeZone: 'UTC' },
            'longDate': { dateStyle: 'long', timeZone: 'UTC' },
            'fullDate': { dateStyle: 'full', timeZone: 'UTC' },
            'h:mm a': { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' }, // 7:06 PM
            'HH:mm': { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' }, // 19:06
            'longTime': { timeStyle: 'long', timeZone: 'UTC' }, // 7:06:32 PM GMT+00:00
            'shortTime': { timeStyle: 'short', timeZone: 'UTC' }, // 7:06 PM
            'dateTime': { dateStyle: 'medium', timeStyle: 'short', timeZone: 'UTC' } // Jan 1, 2035, 7:06 PM
        };

        // Get the format options based on the format string
        const options = formats[format] || formats['mediumDate'];

        // Convert UTC Date to local time string using the provided format
        return new Intl.DateTimeFormat('en-US', options).format(utcDate);
    }
}
