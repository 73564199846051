import { Injectable, NgZone } from '@angular/core';

import { NavigationService } from './navigation.service';
import { SharedDataService } from './shared-data.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { LoadingService } from './loader.service';
import { DataService } from './data.service';
import { ApiUrls } from '../constants/apiUrl.constants';

@Injectable()
export class AuthService {
  redirectUrl: string; // URL to redirect after login
  isChecked = false; // Flag to indicate if a login or sign-up request is in progress

  // tslint:disable-next-line:max-line-length
  constructor(
    private sharedData: SharedDataService, // Service for managing shared data
    private nav: NavigationService, // Service for navigation
    private dataService: DataService, // Service for making HTTP requests
    private notification: NotificationService, // Service for notifications
    private ngZone: NgZone, // Service for executing code inside Angular's zone
    private loader: LoadingService // Service for showing and hiding loaders

  ) {
    // Setting default route to navigate after login
    this.redirectUrl = '/admin/dashboard';
  }
   /**
   * Retrieves the access token from shared data.
   * @returns The access token as a string or null if not available.
   */

  getAccessToken() {
    return this.sharedData.getAttribute('accessToken');
  }
/**
   * Checks the system status and initializes attached systems if available.
   * Redirects user based on the presence of attached systems and cookies.
   */
  checkSystem() {
    this.dataService.get({ url: ApiUrls.Attached_System, isLoader: true,donot_stop_loader:true })
      .subscribe((response: any) => {
        this.sharedData.setAttribute('date-filter', { label: 'Weekly', graph: 'weekly', value: 20, isSelected: true, type: 'static' });

        if (response.result.length) {

          if (this.sharedData.getAttribute('selectedAttachedSystems')) {

          } else {

            this.sharedData.setAttribute('selectedAttachedSystems', response.result[0]);
            this.sharedData.setAttribute('attachedSystems', response.result);
          }
          
          this.nav.navigateTo(['/admin/dashboard']);
          
          
          
          
          //this.shareSystemHaveSystem()
        } else {
          
            this.sharedData.setAttribute('attachedSystems', []);
            this.shareSystem()

          

         
        }
      });
  }
    /**
   * Retrieves the value of a cookie by name.
   * @param name The name of the cookie.
   * @returns The cookie value or null if not found.
   */
  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }
  /**
   * Handles system sharing if systems are already attached.
   */
  shareSystemHaveSystem() {
    let userObj = this.getUserDetail();
    this.dataService.get({ url: ApiUrls.Invites + '?' + 'invite_to=' + userObj.email + '&', isLoader: true, })
      .subscribe((response: any) => {
        let systemsShareSystemsInvited = response.result.records
        systemsShareSystemsInvited = systemsShareSystemsInvited?.filter((system: any) => system.status == 'invited');

        if (systemsShareSystemsInvited.length > 0) {
          this.nav.navigateTo(['auth/accept-invitation']);
        } else {
          this.nav.navigateTo([this.redirectUrl]);
        }
      });
  }
 /**
   * Handles system sharing if no systems are attached.
   */
  shareSystem() {
    let userObj = this.getUserDetail();
    this.dataService.get({ url: ApiUrls.Invites + '?' + 'invite_to=' + userObj.email + '&', isLoader: true, })
      .subscribe((response: any) => {
        this.sharedData.setAttribute('attachedSystems', []);
        let systemsShareSystemsAccepted = response.result.records;
        let systemsShareSystemsInvited = response.result.records;
        
        systemsShareSystemsAccepted = systemsShareSystemsAccepted?.filter((system: any) => system.status === 'accept');
        systemsShareSystemsInvited = systemsShareSystemsInvited?.filter((system: any) => system.status === 'invited');
        
        console.log(systemsShareSystemsAccepted.length); // Corrected property name
        console.log(systemsShareSystemsAccepted);
        

       if(systemsShareSystemsAccepted.length>0){
        this.sharedData.setAttribute('selectedAttachedSystems',systemsShareSystemsAccepted[0]?.system_details);
        this.nav.navigateTo([this.redirectUrl]);
        return
       }
//        if (this.getCookie('IsFirstTimeGetStarted') == null) {

//         this.setCookie('IsFirstTimeGetStarted', 'true', 365);
//         this.nav.navigateTo(['/get-started']);
// return
//       }

        if (systemsShareSystemsInvited.length > 0) {
          this.nav.navigateTo(['auth/accept-invitation']);
        } else {
          console.log('hanu')
          this.nav.navigateTo(['/auth/enable-system']);
        }


      });
  }
  /**
   * Handles user login by sending credentials to the server.
   * @param userData User credentials for login.
   */
  login(userData: any) {
    this.isChecked = true;
    // Referencing this in this because this refers to the promise itthis. Do this let this = this;
    const data = {
      'value': userData,
      'withCredentials': false
    };

    userData.email = userData.email.toLowerCase()

    this.dataService.post({ url: ApiUrls.Admin_Authentication, data: data, isLoader: true ,donot_stop_loader:true})
      .subscribe((response: any) => {
        const result = response.result;
        console.log(result)
        if (response.result !== undefined) {
          if(result.user_details.metrics){
  
          }else{
            result.user_details.metrics='liters'
          }

          this.sharedData.setAttribute('userDetails', result);
          this.sharedData.setAttribute('accessToken', result.access_token);
          this.sharedData.setAttribute('refreshToken', result.refresh_token);

          this.getApplicationConstant();
          if (!result?.user_details?.is_email_verified) {
            this.nav.navigateTo(['/auth/email-verification']);
          } else {
            this.checkSystem();
          }
        } else {
          this.notification.toast(response.error);
        }
        this.isChecked = false;
      },
        () => { this.isChecked = false; }
      );
  }


  //  sign - up

  signUp(userData: any) {
    if (!userData.terms) {
      this.notification.toast('Please agree with Terms Of Use and Privacy Policy!');
      return;
    }
    userData.email = userData.email.toLowerCase()

    userData.gender = "male";
    //userData.phone = "1234567890";

    this.isChecked = true;
    if(userData.middle_name==''){
delete userData.middle_name
    }
    if(userData.middle_name==null){
      delete userData.middle_name
          }

    // Referencing this in this because this refers to the promise itthis. Do this let this = this;
    const data = {
      'value': userData,
      'withCredentials': false
    };

  

    this.dataService.post({ url: ApiUrls.SignUp, data: data, isLoader: true })
      .subscribe((response: any) => {
        const result = response.result;
        if (response.result !== undefined) {
          this.nav.navigateTo(['/auth/login']);
        } else {
          this.notification.toast(response.error);
        }
        this.isChecked = false;
      },
        () => { this.isChecked = false; }
      );
  }
/**
   * Sends an email verification OTP to the user.
   */

  sentEmailVerification() {
    this.dataService.post({ url: ApiUrls.Email_OTP }).subscribe((response: any) => {
      if (response && response.result) {
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
  /**
   * Sets a cookie with a specified name, value, and expiration period.
   * @param name The name of the cookie.
   * @param value The value of the cookie.
   * @param days Number of days until the cookie expires.
   */
  setCookie(name: string, value: string, days: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }
   /**
   * Verifies the user's email with the provided passcode.
   * @param passcode The passcode for email verification.
   */
  emailVerify(passcode: any) {
    if(passcode?.length!=6){
      this.notification.toast('Otp is invalid','danger')
      return
    }
    const userDetail = this.sharedData.getAttribute('userDetails')?.user_details;
    this.dataService.get({ url: ApiUrls.Email_Verification + '/' + userDetail.email.toLowerCase() + '/' + passcode }).subscribe((response: any) => {
      if (response && response.result) {
        this.setCookie('IsFirstTimeGetStarted', 'true', 365);
        var user_details=this.sharedData.getAttribute('userDetails')
        user_details.user_details.is_email_verified=true;
        this.sharedData.setAttribute('userDetails', user_details);
        this.sharedData.restoreSharedData()
        this.nav.navigateTo(['/get-started/']);
      
        //this.nav.navigateToSystem(['/auth/enable-system'], { isFromEmailVerification: true });
      
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
  /**
   * Fetches and sets application constants from the server.
   */
  getApplicationConstant() {
    this.dataService.get({
      url: ApiUrls.Application_Constant,
      isLoader: false
    }).subscribe((response: any) => {
      if (response && response.result) {
        this.sharedData.setAttribute('applicationConstant', response.result);
      }
    });
  }
/**
   * Redirects the user to the login page after logout.
   */
  redirectAfterLogout() {
    this.ngZone.run(() => {
      this.loader.stop();
      console.log('Refresh Token expired');
      this.sharedData.clear();
      this.nav.navigateTo(['/auth/login']);
    });
  }
/**
   * Checks if the user is currently logged in.
   * @returns True if the user is logged in, otherwise false.
   */
  isLoggedIn() {
    return Boolean(this.sharedData.getAttribute('accessToken') && this.sharedData.getAttribute('userDetails'));
  }
/**
   * Retrieves the refresh token from shared data.
   * @returns The refresh token as a string or null if not available.
   */
  getRefreshToken() {
    return this.sharedData.getAttribute('refreshToken');
  }
 /**
   * Refreshes the access token by sending the refresh token to the server.
   * @returns An observable of the response.
   */
  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({ url: ApiUrls.Admin_Refresh_Token, data, isLoader: false });
  }
 /**
   * Logs out the user and clears the session.
   */
  logout() {
    this.dataService.post({ url: ApiUrls.Admin_Logout }).subscribe((result: any) => {
      if (result) {
        this.sharedData.clear();
        this.isChecked = false;
     
      window.location.replace('/auth/login');
        // this.sharedData.clear();
        // this.isChecked = false;
        // this.nav.navigateTo(['/auth/login']);
      }
    });
  }
 /**
   * Resets the user's password by sending a request to the server.
   * @param userData User details for password reset.
   */
  resetPassword(userData: any) {
    const data = {
      'value': userData,
      'withCredentials': false
    };
    this.dataService.post({ url: ApiUrls.Admin_Password_Reset, data: data }).subscribe((result: any) => {
      console.log(result);
      this.nav.navigateTo(['/auth/login']);
      this.notification.toast('Check Your Mail ! Click on reset link.');
    },
      () => { }
    );
  }
/**
   * Sends a request to reset the user's password.
   * @param userData User details for resetting the password.
   */
  forgetPassword(userData: any) {
    // Referencing this in self because this refers to the promise itself. Do this let self = this;
    const self = this,
      data = {
        value: userData,
        noCredentials: true
      };

    data.value.email = data.value.email.toLowerCase()
    this.dataService.post({ url: ApiUrls.Admin_Forget_Password, data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          console.log(response);
          self.notification.toast('Please check your mail for reset link!');
          this.sharedData.setAttribute('forgetPasswordEmail', userData.email);
          this.nav.navigateTo(['/auth/reset-password']);
        } else if (response.error) {
          self.notification.toast(response.error, 'danger');
        }
      },
      error => { }
    );
  }
 /**
   * Updates the user's password.
   * @param password New password details.
   */
  updatePassword(password: any) {
    const data = {
      value: password,
      noCredentials: false
    };
    this.dataService.post({ url: ApiUrls.Admin_Password_Change, data }).subscribe((response: any) => {
      if (response && response.result) {
        this.notification.toast('Password updated successfully!');
        this.nav.navigateTo(['/auth/login']);
        this.sharedData.deleteAttribute('forgetPasswordEmail');
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }
/**
   * Retrieves user details from shared data if the user is logged in.
   * @returns User details object or empty object if not logged in.
   */
  getUserDetail() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details : {};
  }
 /**
   * Checks if the logged-in user is an admin.
   * @returns True if the user is an admin, otherwise false.
   */
  userIsAdmin() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details.is_admin : false;
  }
   /**
   * Updates the logged-in user's profile with the provided data.
   * @param formData Updated user profile data.
   * @returns An observable of the update response.
   */

  updateLoginUser(formData: any) {
    return this.dataService.put({
      url: ApiUrls.Profile_Update,
      data: formData,
      loaderName: 'container-loader'
    });
  }
}



