// import { Injectable } from '@angular/core';
// import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

// @Injectable()
// export class NotificationService {

//     snackBar: MatSnackBar;

//     constructor(private _snackBar: MatSnackBar) {
//         this.snackBar = _snackBar;
//     }

//     public toast(message: string, action: string = '', duration: number = 3000) {
//         this.snackBar.open(message, action, { duration: duration });
//     }

// }
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private _snackBar: MatSnackBar) { }

    public toast(message: string, action: string = '', duration: number = 3000): void {
        this._snackBar.open(message, action, { duration });
    }

}
