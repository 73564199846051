
export enum ApiUrls {
  Admin_Authentication = 'greyter-customer/auth/login',
  Admin_Refresh_Token = 'business/user/auth/refresh',
  Admin_Forget_Password = 'greyter-customer/auth/password/email',
  Admin_Password_Reset = 'greyter-customer/auth/password/reset',
  Admin_Logout = 'greyter-customer/auth/logout',
  Admin_Password_Change = 'greyter-customer/auth/password/change',

  Email_Verification = 'greyter-customer/auth/email/verify',
  Email_OTP = "greyter-customer/auth/email/resend",
  User = 'portal/user',
  User_Update = 'portal/user-update',
  User_List = 'portal/users/list',
  User_List_Search = 'portal/users/list/search',

  Country_Code = 'portal/country-code',
  Application_Constant = 'user/application-constant',
  User_Register = 'portal/users/register',
  Dashbord_Report = 'greyter-customer/dashboard/trends',
  Dashboard_Water_Summary = 'portal/dashboard/water-saving',

  Customer = 'portal/customer',
  Customer_List = 'portal/customers/list',
  Customer_List_Search = 'portal/customers/list/search',
  Customer_List_Create = 'portal/customers/list',

  System = 'greyter-customer/user-attached-systems',
  System_Update = 'greyter-customer/system',
  System_Note = 'greyter-customer/system/notes',

  System_List = 'portal/systems/list',
  System_List_Search = 'portal/systems/list/search',
  System_Create = 'portal/system/create',
  System_Report = 'portal/system/report',
  System_Logs = 'portal/logs',
  System_Logs_Search = 'portal/logs/search',
  System_Notes = 'portal/system/notes',
  Update_system = 'greyter-customer/system',

  Project_Create = 'portal/project',
  Alarm_Create = 'portal/alarm',

  Profile_Update = 'greyter-customer/user',
  Global_settings = 'greyter-customer/user-setting',

  Assign_History = 'portal/assignment-history',

  Logs = 'portal/logs',
  Logs_Search = 'portal/logs/search',
  Log_Export = 'portal/logs/export',
  Log_Export_History = 'portal/logs/export/history',
  File_Upload = 'portal/upload/base64',

  Activity_Alert = 'greyter-customer/system-active-alerts',

  SignUp = 'greyter-customer/auth/register',
  Attached_System = 'greyter-customer/user-attached-systems',
  Add_System = 'greyter-customer/add-system',
  Remove_System = 'greyter-customer/remove-system/',
  // ForgetPass = 'user/auth/password/email'

  Timezone_List = 'greyter-customer/timezone?limit=unlimited&order_by=region,asc',
  Global_Settings = 'greyter-customer/user-setting',
  Base64_Upload = 'greyter-customer/upload/base64',

  //user invites
  Invite = 'greyter-customer/invite',
  Invites = 'greyter-customer/invites',
  Invite_resend = 'greyter-customer/invite-resend',
  Invite_remove = 'greyter-customer/invite-remove',

   //support
   Support_Request = 'greyter-customer/support-request',
   Delete_Account = 'greyter-customer/release-systems-and-delete-account'

}
