import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './modules/app-routing.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

// import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AdminLayoutComponent } from './pages/admin-layout/admin-layout.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxMatTimepickerModule, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { ProfileDetailsComponent } from './pages/profile-details/profile-details.component';
//import { InviteMembersComponent } from './pages/invite-members/invite-members.component';
//import { UserProfileComponent } from './pages/user-profile/user-profile.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#fd6b00',
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  bgsType: SPINNER.ballScaleMultiple, // background spinner type
  fgsColor: '#fd6b00',
  fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
  fgsSize: 80,
  overlayColor: 'rgb(255,255,255)',
  hasProgressBar: false,
  maxTime: -1,
  minTime: 500
};

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PageNotFoundComponent,
    ProfileDetailsComponent,
    //InviteMembersComponent
    //UserProfileComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxWebstorageModule.forRoot({
      prefix: 'greyter-consumer',
      separator: '.',
      caseSensitive: true
    }),
    RxReactiveFormsModule,
    InfiniteScrollModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    // For UTC Timezone
    MatMomentDateModule
  ],
  providers: [
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true }
      // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }



