// import { ComponentType } from '@angular/cdk/portal';
// import { Injectable } from '@angular/core';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// import { Observable } from 'rxjs';
// import { ImagePreviewComponent } from '../components/image-preview/image-preview.component';

// @Injectable({
//   providedIn: 'root'
// })
// export class DialogService {

//   isAccessDeniedOpen = false;
//   constructor(
//     private dialog: MatDialog,
//   ) { }

//   openDialogComponent(component: ComponentType<unknown>, data: { errors?: any[], data?: any, class: string[] }, width?: any, height?: any): Observable<any> {
//     const config: any = {
//       width: width || '400px',
//       data: data || {},
//       panelClass: data?.class,
//       height: height
//     };
//     console.log(config)
//     console.log(component)
//     const dialogRef = this.dialog.open(component, config);
//     return dialogRef.afterClosed();
//   }

//   openImagePreview(data: { imgUrl: string, width?: any }) {
//     const dialogRef = this.dialog.open(ImagePreviewComponent, {
//       width: data?.width || '400px',
//       data: data
//     });
//     return dialogRef.afterClosed();
//   }

//   closeAllDailog(): void {
//     this.dialog.closeAll();
//   }
// }
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImagePreviewComponent } from '../components/image-preview/image-preview.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  isAccessDeniedOpen = false;

  constructor(
    private dialog: MatDialog,
  ) { }

  openDialogComponent(component: ComponentType<unknown>, data: { errors?: any[], data?: any, class: string[] }, width?: string, height?: string): Observable<any> {
    const config: any = {
      width: width || '400px',
      height: height || 'auto',
      data: data || {},
      panelClass: data?.class || []
    };
    console.log(config);
    console.log(component);
    const dialogRef = this.dialog.open(component, config);
    return dialogRef.afterClosed();
  }

  openImagePreview(data: { imgUrl: string, width?: string }) {
    const dialogRef = this.dialog.open(ImagePreviewComponent, {
      width: data?.width || '400px',
      data: data
    });
    return dialogRef.afterClosed();
  }

  closeAllDialogs(): void {
    this.dialog.closeAll();
  }
}
